<template>
    <div class="ta-c pt50">
        <div class="alert red">
            {{ $t('auth.forbidden') }}
        </div>
        <div class="action">
            <router-link
                class="button mt30"
                :to="{ name: 'auth-logout' }"
            >
                {{ $t('auth.logout') }}
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {

        name: 'Forbidden',

        /**
         * @inheritDoc
         */
        created() {
            if (!this.$store.getters['auth/isLoggedIn']) {
                this.$router.push({ name: 'auth-logout' });
            }
        },

    };
</script>
