<template>
    <div>
        <form
            id="verify_token"
            @submit.prevent="loginWithOtp(settings.selectedChannel, model.code)"
        >
            <y-form-field
                v-model="model.code"
                v-validate="'required'"
                type="text"
                name="verify_token"
            />

            <y-button
                block
                color="blue"
                button-type="submit"
                form="verify_token"
                :disabled="!model.code"
                loading-on="submitting-login-with-otp"
            >
                {{ $t('auth.send') }}
            </y-button>

            <y-button
                v-if="showResend"
                block
                color="gray"
                class="mt10"
                loading-on="submitting-login-send-otp"
                @click.prevent="loginSendOtp(settings.selectedChannel)"
            >
                {{ $t('auth.get_code.resend') }}
            </y-button>
            <div
                v-else
                class="button gray block mt10 cur-d"
            >
                <span>{{ counter | digits }}</span>
            </div>
        </form>
    </div>
</template>

<script>

    // Mixins
    import AuthMixin from '@/modules/auth/mixins/Auth';

    // Components
    import { YFormField } from '@deps';

    export default {
        name: 'OTPVerifyCode',

        components: {
            YFormField,
        },

        mixins: [AuthMixin],

        props: {
            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model            : {},
                username         : this.settings.username,
                startCountMinutes: this.setting('login_form.otp_token_expires_time'),
            };
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.runCountdown();
        },

        methods: {
            /**
             * Custom Login Send Otp action
             *
             * @param {object} response - Login Send Otp response
             */
            afterSuccessLoginSendOtp(response) {
                this.$toast.success(response.data.results.message.replace('%channel%', this.$t(`auth.otp.channels.${this.settings.selectedChannel}`)));
                this.runCountdown();
            },

            /**
             * Tasks to be done after login
             */
            afterSuccessLogin() {
                const redirect = this.cloneDeep(this.$store.getters['auth/redirect']);
                if (redirect) {
                    this.$store.dispatch('auth/resetRedirect').then(() => {
                        this.$router.push(redirect);
                    });
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            },
        },
    };
</script>
