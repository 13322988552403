<template>
    <div>
        <y-loading
            :active="$wait.is('loading-post')"
            height="200px"
        >
            <template v-if="!codes">
                <!-- QR code -->
                <div class="ta-c mb30">
                    <img
                        :src="qr"
                        alt="QR Code"
                    >
                </div>

                <p>{{ $t('profile.google.text') }}</p>

                <form
                    id="faq-form"
                    @submit.prevent="submit(save)"
                >
                    <y-form-field
                        v-model="model"
                        type="number"
                        name="google_code"
                        validation="required|length:6"
                    />
                </form>
            </template>

            <div
                v-if="!codes"
                class="ta-l w-100"
            >
                <y-button
                    color="blue"
                    block
                    form="faq-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </y-loading>
    </div>
</template>

<script>
    import FormMixin from '@/mixins/Form';
    import { YFormField } from '@deps';
    
    export default {
        name: 'TwoFAActivation',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                qr   : null,
                model: null,
                codes: null,
            };
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetch();
        },

        methods: {
            /**
             * Save form
             */
            fetch() {
                this.$wait.start('loading-post');

                this.$services.Person.generateTwoFactorAuthentication().then((response) => {
                    this.qr = response.data.results.token;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-post'));
            },

            /**
             * Save form
             */
            save() {
                this.$wait.start('submitting-form');
                const params = {
                    code : this.model,
                    class: 'google',
                };
                return this.$services.Person.enableTwoFactorAuthentication(params).then((response) => {
                    this.$toast.success(this.$t('profile.google.activate_success'));
                    this.$store.commit('auth/login', response.data.metadata.token);
                    this.codes = response.data.metadata.backup_codes;
                    this.$emit('codes', this.codes);
                    this.loginWithToken();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

            /**
             * Login with token
             */
            loginWithToken() {
                const params = {
                    code: this.model,
                };
                return this.$services.Person.loginSecondFactor(params).then((response) => {
                    this.$emit('tokenVerified', response);
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };
</script>
