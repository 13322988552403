<template>
    <y-panel class="ov-h">
        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="300px"
        >
            <div
                v-if="settings.usernameFields"
                class="auth-form"
            >
                <transition
                    name="fadeDown"
                    mode="out-in"
                >
                    <get-username
                        v-if="step === 1"
                        key="get-username"
                        v-model="model"
                        :settings="settings"
                        @done="next"
                    />
                    <token-channel
                        v-if="step === 2"
                        key="token-channel"
                        v-model="model"
                        :settings="settings"
                        @done="next"
                    />
                    <get-code
                        v-if="step === 3"
                        key="get-code"
                        v-model="model"
                        :settings="settings"
                        @done="next"
                    />
                    <new-password
                        v-if="step === 4"
                        key="new-password"
                        v-model="model"
                        :settings="settings"
                    />
                </transition>
            </div>
        </y-loading>
    </y-panel>
</template>

<script>
    import GetUsername from '@/modules/auth/components/auth/GetUsername';
    import TokenChannel from '@/modules/auth/components/auth/TokenChannel';
    import GetCode from '@/modules/auth/components/auth/GetCode';
    import NewPassword from '@/modules/auth/components/auth/NewPassword';

    export default {
        name: 'ForgetPassword',

        components: {
            GetUsername,
            GetCode,
            NewPassword,
            TokenChannel,
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('auth.page_title.forget'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                step : 1,
                model: {},

                settings: {
                    availableChannels: [
                        {
                            label: this.$t('auth.channels.sms'),
                            value: 'sms',
                        },
                        {
                            label: this.$t('auth.channels.email'),
                            value: 'mail',
                        },
                    ],
                    usernameFields: null,

                    recaptcha: null,
                },
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.logout();
            this.getForgotPasswordConfig();
        },

        methods: {
            /**
             * Change steps to next
             *
             * @param settings
             */
            next(settings) {
                this.settings = { ...this.settings, ...settings };
                this.step += 1;
            },

            /**
             * Logout user
             */
            logout() {
                this.$store.commit('auth/logout');
            },

            /**
             * Get valid fields for forgot password
             */
            getForgotPasswordConfig() {
                this.$wait.start('loading-page');
                return this.$services.Person.getUsernameFields().then((response) => {
                    const { metadata } = response.data;

                    this.$set(this.settings, 'usernameFields', metadata.username_fields);

                    const recaptcha = this.get(metadata, 'recaptcha');
                    if (recaptcha.active) {
                        this.$set(this.settings, 'recaptcha', {
                            active: true,
                            key   : recaptcha.key,
                            size  : recaptcha.size || 'invisible',
                        });
                    }
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },
        },
    };
</script>
