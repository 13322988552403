<template>
    <div>
        <p>
            {{ $t('auth.token_channel.sent_to', [maskedUsername]) }}
        </p>
        <form
            id="channel-form"
            @submit.prevent="submit(submitChannel)"
        >
            <y-form-field
                v-model="model.channel"
                v-validate="'required'"
                name="receive_channel"
                :placeholder="$t('auth.username')"
                type="radio"
                :options="channelsOptions"
            />

            <y-button
                block
                color="blue"
                button-type="submit"
                form="channel-form"
                loading-on="submitting-form"
                :disabled="!model.channel"
                @click="$emit('apply')"
            >
                {{ $t('auth.send') }}
            </y-button>
        </form>
    </div>
</template>

<script>
    import FormMixin from '@/mixins/Form';
    import { YFormField } from '@deps';
    import { digits } from '@nodes/helpers/number';
    import { isEmail } from '@nodes/helpers/validate';

    export default {
        name: 'ForgetTokenChannel',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Model value
             */
            value: {
                type: Object,
            },

            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value,
            };
        },

        computed: {
            /**
             * Return masked string based on username type
             */
            maskedUsername() {
                const { username } = this.settings;
                if (this.isUsingEmail) {
                    return username.replace(/^(.{2})[^@]+/, '$1****');
                }
                if (this.isUsingPhone) {
                    const str = [username[0], username[1], 'xxxx', username[username.length - 2], username[username.length - 1]];
                    return digits(str.join(''), this.$i18n.locale);
                }
                return username;
            },

            /**
             * Check whether input username is an email address
             */
            isUsingEmail() {
                return isEmail(this.settings.username);
            },

            /**
             * Check whether input username is a phone number
             */
            isUsingPhone() {
                const { username } = this.settings;
                return username.split('').every((char) => !Number.isNaN(Number(char)));
            },

            /**
             * Map available channels to an array of channel's label
             */
            channelsOptions() {
                return this.settings.availableChannels.map((channel) => channel.label);
            },
        },

        methods: {

            /**
             * Submit channel and username to generate and send a token to the desired channel
             */
            submitChannel() {
                const channel = this.settings.availableChannels[this.model.channel].value;
                const params = {
                    user_name: this.settings.username,
                    channel,
                };

                return this.$services.Person.passwordRecoveryRequest(params).then((response) => {
                    this.$toast.success(response.data.results.message);
                    this.$emit('done', { selectedChannel: params.channel, ...response.data.metadata });
                }).catch((err) => {
                    this.handleError(err);
                });
            },
        },
    };
</script>
