<template>
    <div>
        <form
            id="verify_token"
            key="verify_token"
            @submit.prevent="submit(verifyToken)"
        >
            <y-form-field
                v-model="model.token"
                v-validate="'required'"
                name="recovery_token"
                type="number"
                :disabled="isCodeExpired"
            />
            <y-button
                block
                color="blue"
                button-type="submit"
                form="verify_token"
                loading-on="submitting-form"
                :disabled="isCodeExpired"
            >
                {{ $t('auth.verify') }}
            </y-button>
            <span
                :class="resendBtnClasses"
                @click.prevent="resendToken"
            >
                {{ resendTimer === 0 ? $t('auth.get_code.resend') : formatTimerString(resendTimer) }}
            </span>
        </form>
    </div>
</template>

<script>

    // Mixins
    import FormMixin from '@/mixins/Form';

    import { YFormField } from '@deps';
    import { digits } from '@nodes/helpers/number';

    export default {
        name: 'ForgetGetCode',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Model value
             */
            value: {
                type: Object,
            },

            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model      : this.value,
                resendTimer: this.settings.expired_time ? Number(this.settings.expired_time) * 60 : 300,

                resendDefaultTime: this.settings.expired_time ? Number(this.settings.expired_time) * 60 : 300,

                intervalId: null,
            };
        },

        computed: {
            /**
             * Check whether if code expired
             */
            isCodeExpired() {
                return this.resendTimer === 0;
            },

            /**
             * Return class list of resend button
             */
            resendBtnClasses() {
                const classes = ['button gray block'];
                if (this.resendTimer !== 0) {
                    classes.push('disabled');
                }
                if (this.$wait.is('resending_token')) {
                    classes.push('loader loading');
                }
                return classes;
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.initInterval();
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            clearInterval(this.intervalId);
        },

        methods: {
            /**
             * Verify entered token and move to next step
             */
            verifyToken() {
                const params = {
                    user_name: this.model.username,
                    token    : this.model.token,
                };
                return this.$services.Person.passwordRecoveryVerifyToken(params).then(() => {
                    this.$toast.success(this.$t('auth.messages.verify_token.success'));
                    this.$emit('done');
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Start timer interval
             */
            initInterval() {
                this.intervalId = setInterval(this.intervalCallback, 1000);
                if (this.resendTimer !== this.resendDefaultTime) {
                    this.resendTimer = this.resendDefaultTime;
                }
            },

            /**
             * Timer interval callback
             */
            intervalCallback() {
                if (this.resendTimer > 0) {
                    this.resendTimer -= 1;
                }

                if (this.resendTimer === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            },

            /**
             * Return resend timer string
             *
             * @param timer
             */
            formatTimerString(timer) {
                const remainingMinutes = (timer / 60).toPrecision()[0];
                const remainingSeconds = timer % 60;
                return digits(`${remainingMinutes}:${remainingSeconds}`, this.$i18n.locale);
            },

            /**
             * Resend password reset token
             */
            resendToken() {
                this.$wait.start('resending_token');
                const params = {
                    user_name: this.settings.username,
                    channel  : this.settings.selectedChannel,
                };

                return this.$services.Person.passwordRecoveryRequest(params).then((response) => {
                    this.$toast.success(response.data.results.message);
                    this.initInterval();
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('resending_token');
                });
            },

        },
    };
</script>
