<template>
    <div>
        <form
            id="reset_password"
            key="reset_password"
            @submit.prevent="verifyRecaptcha"
        >
            <y-form-field
                v-model="model.password"
                v-validate="'required'"
                name="password"
                type="password"
            />

            <y-form-field
                v-model="model.password2"
                v-validate="'required'"
                name="password2"
                type="password"
            />

            <y-recaptcha
                v-if="hasRecaptcha"
                ref="recaptcha"
                :site-key="settings.recaptcha.key"
                :size="settings.recaptcha.size"
                @verify="setsettings.Recaptcha"
            />

            <y-button
                block
                color="blue"
                button-type="submit"
                form="reset_password"
                loading-on="submitting-form"
            >
                {{ $t('auth.reset_password') }}
            </y-button>
        </form>
    </div>
</template>

<script>

    // Mixins
    import FormMixin from '@/mixins/Form';

    // Components
    import { YFormField, YRecaptcha } from '@deps';

    export default {
        name: 'ForgetNewPassword',

        components: {
            YFormField,
            YRecaptcha,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Model value
             */
            value: {
                type: Object,
            },

            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                submitting: false,

                model: this.value,
            };
        },

        computed: {
            /**
             * Determine whether if recaptcha is enabled
             */
            hasRecaptcha() {
                return !!this.settings.recaptcha && this.settings.recaptcha.active;
            },
        },

        methods: {
            /**
             * Verify recaptcha
             */
            verifyRecaptcha() {
                if (this.isDev || !this.hasRecaptcha) {
                    this.submit(this.resetPassword);
                } else {
                    this.$refs.recaptcha.verify();
                }
            },

            /**
             * Set recaptcha and submit
             *
             * @param token
             */
            setRecaptcha(token) {
                this.model['g-recaptcha-response'] = token;
                this.submit(this.resetPassword);
            },

            /**
             * Reset password and redirect to login page
             */
            resetPassword() {
                const params = {
                    user_name: this.model.username,
                    password : this.model.password,
                    password2: this.model.password2,
                    token    : this.model.token,
                };

                if (this.isProd) {
                    params.recaptcha = this.model['g-recaptcha-response'];
                }

                return this.$services.Person.passwordRecovery(params).then(() => {
                    this.$toast.success(this.$t('auth.messages.reset_password.success'));
                    this.$router.push({ name: 'auth-login' });
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };
</script>
