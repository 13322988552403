<template>
    <div
        id="verifyCode"
        key="verifyCode"
    >
        <p>{{ $t('auth.google.verify_text') }}</p>

        <form
            id="twofa-form"
            @submit.prevent="submit(loginWithToken)"
        >
            <y-form-field
                v-model="model"
                type="number"
                name="google_code"
                validation="required|length:6"
            />
        </form>

        <y-button
            block
            color="blue"
            button-type="submit"
            form="twofa-form"
            loading-on="submitting-form"
        >
            {{ $t('fields.verify_code') }}
        </y-button>
    </div>
</template>

<script>

    // Mixins
    import FormMixin from '@/mixins/Form';

    import { YFormField } from '@deps';

    export default {
        name: 'TwoFAVerifyCode',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value,
            };
        },

        methods: {
            /**
             * Login with token
             */
            loginWithToken() {
                const params = {
                    code: this.model,
                };
                return this.$services.Person.loginSecondFactor(params).then((response) => {
                    this.$emit('tokenVerified', response);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

        },
    };
</script>
