<template>
    <div :key="keyHelper">
        <y-panel :title="$t('auth.login')">
            <template v-if="!twoFA && !needTwoFA">
                <y-loading
                    class="panel-grid-layout"
                    :active="$wait.is('loading-page')"
                    height="300px"
                >
                    <div class="auth-form">
                        <form
                            v-if="form"
                            id="main-form"
                            @submit.prevent="login"
                        >
                            <y-form-field
                                v-model="model.username"
                                type="text"
                                name="username"
                                :label="usernameLabel"
                                :validation="form.username.validation"
                            />

                            <y-form-field
                                v-model="model.password"
                                type="password"
                                name="password"
                                :validation="form.password.validation"
                            />

                            <y-form-field
                                v-if="form.remember_me * 1"
                                v-model="model.remember"
                                type="checkbox"
                                name="remember"
                            />

                            <y-recaptcha
                                v-if="recaptcha.active"
                                ref="recaptcha"
                                class="pos-r mb0"
                                :site-key="recaptcha.key"
                                :size="recaptcha.size"
                                @verify="beforeLogin"
                            />

                            <div class="d-f flex-column justify-content-center align-items-center">
                                <router-link
                                    v-if="form.links.recovery_password * 1"
                                    :to="{ name: 'auth-forget-password' }"
                                    class="form-link whs-nw"
                                >
                                    {{ $t('auth.forget_link') }}
                                </router-link>
                                <router-link
                                    v-if="$settings('login_form.otp_system') * 1"
                                    :to="{ name: 'auth-otp-login' }"
                                    class="form-link whs-nw mt10"
                                >
                                    {{ $t('auth.otp.title') }}
                                </router-link>
                            </div>
                        </form>
                    </div>
                </y-loading>
            </template>

            <template v-else-if="needTwoFA">
                <div class="auth-form">
                    <template v-if="codes">
                        <p v-html="$t('profile.google.generated_backup_codes_desc')" />
                        <y-row class="mt30">
                            <y-col
                                v-for="code in codes"
                                :key="code"
                                xs="6"
                            >
                                <div class="code-item">
                                    {{ code | digits }}
                                </div>
                            </y-col>
                        </y-row>

                        <y-button
                            color="blue"
                            block
                            loading-on="submitting-form"
                            disabled-on="loading-page"
                            class="mt20"
                            @click="handleLoginResponse(twoFaResponse)"
                        >
                            {{ $t('auth.page_title.login') }}
                        </y-button>
                    </template>

                    <y-two-f-a-activation
                        v-if="needTwoFA"
                        @codes="generatedCodes"
                        @tokenVerified="handleLoginResponseAfter2Fa"
                    />
                </div>
            </template>

            <template v-else-if="twoFA">
                <div class="auth-form">
                    <y-two-f-a-verify-code
                        v-if="twoFA"
                        @tokenVerified="handleLoginResponse"
                    />
                </div>
            </template>

            <template v-if="!twoFA && !needTwoFA">
                <y-button
                    key="loginFirstStep"
                    color="blue"
                    block
                    form="main-form"
                    loading-on="submitting-form"
                    disabled-on="loading-page"
                    class="mt20"
                >
                    {{ $t('auth.page_title.login') }}
                </y-button>
            </template>
        </y-panel>

        <div class="auth-alt-links">
            <a
                v-if="form && form.links.register"
                href="#"
            >{{ $t('auth.alt.register') }}</a>
        </div>
    </div>
</template>

<script>
    // Mixins
    import FormMixin from '@/mixins/Form';
    import AuthMixin from '@/modules/auth/mixins/Auth';

    // Components
    import { YFormField } from '@deps';
    import YTwoFAActivation from '@/modules/auth/components/auth/TwoFAActivation';
    import YTwoFAVerifyCode from '@/modules/auth/components/auth/TwoFAVerifyCode';

    export default {

        name: 'Login',

        components: {
            YFormField,
            YTwoFAActivation,
            YTwoFAVerifyCode,
        },

        mixins: [FormMixin, AuthMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('auth.page_title.login'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                remember     : false,
                model        : {},
                form         : null,
                Service      : this.$services.Person,
                submitting   : false,
                number       : null,
                codes        : null,
                twoFaResponse: null,

                separator: this.$i18n.locale === 'fa' ? '، ' : ', ',
            };
        },

        computed: {

            /**
             * Return username label
             */
            usernameLabel() {
                if (!this.form) {
                    return this.$t('fields.username');
                }
                let accept = this.get(this.form, 'username.accept', []);
                if (typeof accept === 'string') {
                    accept = [accept];
                }

                accept = accept.map((field) => this.$t(`fields.${field}`));
                return accept.join(this.separator);
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.$store.getters['auth/isTokenValid'] === false || this.$route.query.logout) {
                // send a signal to App.js for clearing check token
                this.$root.$emit('clearIntervalCheckToken');

                this.logout().then(() => {
                    this.fetchForm();
                });
            } else if (this.$store.getters['auth/isLoggedIn']) {
                this.$router.push({ name: 'auth-forbidden' });
            }
        },

        methods: {

            /**
             * fetch login form fields
             */
            fetchForm() {
                this.$wait.start('loading-page');
                this.$services.Person.loginConfigs().then((response) => {
                    this.form = response.data.results;
                    this.setRecaptchaType();

                    let locale = this.form.default_locale || 'fa';
                    if (locale !== 'fa') {
                        locale = 'en';
                    }
                    this.setLocale(locale);

                    this.$wait.end('loading-page');
                });
            },

            /**
             * Set recaptcha type
             */
            setRecaptchaType() {
                const recaptcha = this.get(this.form, 'recaptcha');

                if (recaptcha.active) {
                    this.recaptcha = {
                        active: true,
                        key   : recaptcha.key,
                        size  : recaptcha.size || 'invisible',
                    };
                }
            },

            /**
             * Tasks to be done after login
             */
            afterSuccessLogin() {
                const redirect = this.cloneDeep(this.$store.getters['auth/redirect']);
                if (redirect) {
                    this.$store.dispatch('auth/resetRedirect').then(() => {
                        this.$router.push(redirect);
                    });
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            },

            /**
             * Get the generated codes
             * 
             * @param {Array} codes
             */
            generatedCodes(codes) {
                this.$set(this, 'codes', codes);
            },

            /**
             * Save Response for handle login after 2fa
             * 
             * @param {object} response
             */
            handleLoginResponseAfter2Fa(response) {
                this.$set(this, 'twoFaResponse', response);
            },
        },

    };
</script>
