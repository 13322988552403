<template>
    <div v-html="`Register`" />
</template>

<script>
    export default {

        name: 'Register',

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('auth.page_title.register'),
            };
        },

    };
</script>
