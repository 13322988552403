<template>
    <div>
        <form
            id="select_channel"
            @submit.prevent="channelsHandle"
        >
            <y-form-field
                v-if="Object.keys(settings.availableChannels).length"
                v-model="model.selectedChannel"
                v-validate="'required'"
                type="radio"
                name="select_channel"
                :options="settings.availableChannels"
            />

            <y-button
                block
                color="blue"
                button-type="submit"
                form="select_channel"
                :disabled="!model.selectedChannel"
                loading-on="submitting-login-send-otp"
            >
                {{ $t('auth.send') }}
            </y-button>
        </form>
    </div>
</template>

<script>

    // Mixins
    import AuthMixin from '@/modules/auth/mixins/Auth';

    // Components
    import { YFormField } from '@deps';

    export default {
        name: 'OTPSelectChannel',

        components: {
            YFormField,
        },

        mixins: [AuthMixin],

        props: {
            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : {},
                username: this.settings.username,
            };
        },

        methods: {
            /**
             * Handle selected channel
             */
            channelsHandle() {
                if (this.model.selectedChannel === 'two_factor') {
                    this.$emit('done', this.model);
                } else {
                    this.loginSendOtp(this.model.selectedChannel);
                }
            },

            /**
             * Custom Login Send Otp action
             *
             * @param {object} response - Login Send Otp response
             */
            afterSuccessLoginSendOtp(response) {
                this.$toast.success(response.data.results.message.replace('%channel%', this.$t(`auth.otp.channels.${this.model.selectedChannel}`)));
                this.$emit('done', this.model);
            },
        },
    };
</script>
