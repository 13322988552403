/*-----------------------------------------------------------------
- Auth Mixin
-----------------------------------------------------------------*/
import Cookies from 'js-cookie';
import { generateId } from '@nodes/helpers/string';
import FormMixin from '@/mixins/Form';
import { YRecaptcha } from '@deps';
import { digits } from '@nodes/helpers/number';

export default {
    name: 'AuthMixin',

    mixins: [FormMixin],

    components: {
        YRecaptcha,
    },

    /**
     * @inheritDoc
     */
    data() {
        return {
            model             : {},
            remember          : false,
            Service           : this.$services.Person,
            loginAfterRegister: true,
            form              : {},

            recaptcha: {
                active: false,
            },

            username         : '',
            recoveryChannel  : 'sms',
            startCountMinutes: 3,
            showResend       : true,
            counter          : '',
            receiveToken     : '',
            password         : '',
            password2        : '',
            loginAfterForget : true,

            needTwoFA: null,
            twoFA    : null,
            token    : null,
            keyHelper: generateId(),
        };
    },

    methods: {
        /*-----------------------------------------------------------------
        - Login Methods
        -----------------------------------------------------------------*/

        /**
         * Do nothing!
         */
        doNothing() {
        },

        /**
         * Logout user from vuex
         *
         * @returns {Promise<any>}
         */
        logout() {
            if (localStorage.getItem('token')) {
                this.$services.Person.logout().then(this.doNothing).catch(this.doNothing);
            }
            return this.$store.dispatch('auth/logout');
        },

        /**
         * Login user
         * (pass to form submit event)
         */
        login() {
            if (!this.recaptcha.active || this.recaptcha.size !== 'invisible') {
                this.submit(this.doLogin);
            } else {
                this.verifyRecaptcha();
            }
        },

        /**
         * Verify recaptcha token
         */
        verifyRecaptcha() {
            this.submitting = true;
            this.$refs.recaptcha.verify();
        },

        /**
         * Set token and start login
         * (set in recaptcha field)
         *
         * @param token
         */
        beforeLogin(token) {
            this.model['g-recaptcha-response'] = token;
            if (this.recaptcha.size === 'invisible') {
                this.submit(this.doLogin);
            }
        },

        /**
         * Do login
         *
         * @returns {Function | any | Promise<any> | Promise<any> | undefined}
         */
        doLogin() {
            return this.Service.login(this.model).then((response) => {
                this.token = response.data.results.access_token;
                this.$http.defaults.headers.common.Authorization = `Bearer ${this.token}`;

                if (response.data.results.need_2fa) {
                    this.needTwoFA = true;
                } else if (response.data.results.requires_2fa) {
                    this.twoFA = true;
                } else {
                    this.handleLoginResponse(response);
                }
            }).catch((error) => {
                if (this.recaptcha.active && this.recaptcha.size === 'invisible') {
                    this.$refs.recaptcha.reset();
                }
                this.handleError(error, this.$t('messages.login.error'));
            });
        },

        /**
         * Handle successful login response
         *
         * @param response
         */
        handleLoginResponse(response) {
            const accessToken = (this.twoFA && this.token) ? this.token : response.data.results.access_token;

            // Set cookie if remember is checked
            if (this.form.remember_me) {
                Cookies.set('token', accessToken, 1);
            }

            if (this.$toast) {
                // Show login message
                this.$toast.success(this.$t('messages.login.success'));
            }

            // Set to Vuex
            this.$store.dispatch('auth/login', accessToken).then(() => {
                this.afterSuccessLogin();
            });
        },

        /**
         * Custom login action
         * Should be overwritten in login page
         */
        afterSuccessLogin() {

        },

        /*-----------------------------------------------------------------
        - Register
        -----------------------------------------------------------------*/

        /**
         * Register User
         * (pass to form submit event)
         */
        register() {
            if (this.isDev || this.recaptcha.size !== 'invisible') {
                this.submit(this.doRegister);
            } else {
                this.verifyRecaptcha();
            }
        },

        /**
         * Set token and start register
         * (set in recaptcha field)
         *
         * @param token
         */
        beforeRegister(token) {
            this.model['g-recaptcha-response'] = token;
            if (this.recaptcha.size === 'invisible') {
                this.submit(this.doRegister);
            }
        },

        /**
         * Register new user
         *
         * @returns {*}
         */
        doRegister() {
            return this.Service.register(this.model).then((response) => {
                this.token = response.data.results.access_token;

                if (this.$toast) {
                    // Show register message
                    this.$toast.success(this.$t('messages.register.success'));
                }

                if (this.loginAfterRegister) {
                    // Set to Vuex
                    this.$store.commit('auth/login', this.token);
                }

                this.afterSuccessRegister(response);
            }).catch((error) => {
                if (this.recaptcha.active && this.recaptcha.size === 'invisible') {
                    this.$refs.recaptcha.reset();
                }
                this.handleError(error, this.$t('messages.login.error'));
            });
        },

        /**
         * Custom register action
         * Should be overwritten in register page
         */
        afterSuccessRegister() {

        },

        /**
         * Set login locale
         *
         * @param code
         */
        setLocale(code) {
            if (this.$i18n.locale === code) {
                return;
            }
            this.$i18n.locale = code;
            // find locale
            const defaultLocale = this.$configs.locales.options.find((locale) => locale.code === code);
            const locale = this.$config('i18n.options')?.find((item) => item.code === this.$i18n.locale) || defaultLocale;

            // Set `dir` and `lang` attributes of html
            const html = document.querySelector('html');
            html.setAttribute('dir', locale.dir);
            html.setAttribute('lang', locale.code);

            // Set axios locale header
            this.$http.defaults.headers.common.locale = locale.code;

            this.keyHelper = generateId();
        },

        /*-----------------------------------------------------------------
        - Forget
        -----------------------------------------------------------------*/

        /**
         * Set recaptcha token
         * (set in recaptcha field)
         *
         * @param {string | number} token - Google recaptcha token
         */
        getToken(token) {
            this.model['g-recaptcha-response'] = token;
        },

        /**
         * Verify recaptcha
         */
        verifyForgetRecaptcha() {
            if (this.recaptcha.active && this.recaptcha.size === 'invisible') {
                this.$refs.recaptcha.verify();
            }
        },

        /**
         * Reset recaptcha
         */
        resetRecaptcha() {
            if (this.recaptcha.active && this.recaptcha.size === 'invisible') {
                this.$refs.recaptcha.reset();
            }
        },

        /**
         * Start recovery request
         * (set in password recovery request form | First step)
         *
         * @returns {Promise}
         */
        async recoverRequest() {
            if (!this.get(this.model, 'g-recaptcha-response')) {
                await this.verifyForgetRecaptcha();
            }
            await this.passwordRecoveryRequest();
        },

        /**
         * Send password recovery request
         *
         * @returns {Promise}
         */
        passwordRecoveryRequest() {
            this.$wait.start('submitting-forget-request');

            const params = {
                channel               : this.recoveryChannel,
                user_name             : this.username,
                'g-recaptcha-response': this.model['g-recaptcha-response'],
            };

            return this.$services.Person.passwordRecoveryRequest(params).then((response) => {
                this.$toast.success(response.data.results.message);
                this.runCountdown();

                this.afterSuccessPasswordRecoveryRequest(response);
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-forget-request');
            });
        },

        /**
         * Custom password recovery request action
         * Should be overwritten in forget page
         *
         * @param {object} response - password recovery request response
         */
        afterSuccessPasswordRecoveryRequest(response) { // eslint-disable-line no-unused-vars

        },

        /**
         * Countdown of verifyToken
         */
        runCountdown() {
            this.showResend = false;
            this.counter = `0${this.startCountMinutes}:00`;
            const duration = (this.startCountMinutes * 60) - 1;
            let timer = duration;
            let minutes;
            let seconds;

            const myCountdown = setInterval(() => {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? `0${minutes}` : minutes;
                seconds = seconds < 10 ? `0${seconds}` : seconds;

                this.counter = `${minutes}:${seconds}`;

                // eslint-disable-next-line no-plusplus
                if (--timer < 0) {
                    timer = duration;
                }

                if (this.counter === '00:00') {
                    this.showResend = true;
                    clearInterval(myCountdown);
                }
            }, 1000);
        },

        /**
         * Verify token
         * (set in verify token form | Second step)
         *
         * @returns {Promise}
         */
        verifyToken() {
            this.$wait.start('submitting-verify-token');

            const params = {
                token                 : digits(this.receiveToken, 'en'),
                user_name             : this.username,
                'g-recaptcha-response': this.model['g-recaptcha-response'],
            };

            return this.$services.Person.passwordRecoveryVerifyToken(params).then(() => {
                this.$toast.success(this.$t('auth.forget.verify_token.success_msg'));

                this.afterSuccessVerifyToken();
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-verify-token');
            });
        },

        /**
         * Custom verify token action
         * Should be overwritten in forget page
         */
        afterSuccessVerifyToken() {

        },

        /**
         * Reset password
         * (set in reset password form | Final step)
         *
         * @returns {Promise}
         */
        resetPassword() {
            this.$wait.start('submitting-reset-password');

            const params = {
                user_name             : this.username,
                password              : this.password,
                password2             : this.password2,
                token                 : digits(this.receiveToken, 'en'),
                'g-recaptcha-response': this.model['g-recaptcha-response'],
            };

            return this.$services.Person.passwordRecovery(params).then((response) => {
                this.token = response.data.results.access_token;

                // Show forget message
                if (this.$toast) {
                    this.$toast.success(this.$t('auth.forget.reset_password.success_msg'));
                }

                if (this.loginAfterForget) {
                    // Set to Vuex
                    this.$store.dispatch('auth/login', this.token);

                    this.$http.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                }

                this.afterSuccessResetPassword(response);
            }).catch((error) => {
                this.handleError(error);
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
            }).finally(() => {
                this.$wait.end('submitting-reset-password');
            });
        },

        /**
         * Custom reset password action
         * Should be overwritten in forget page
         *
         * @param {object} response - reset password response
         */
        afterSuccessResetPassword(response) { // eslint-disable-line no-unused-vars

        },

        /*-----------------------------------------------------------------
        - OTP Login
        -----------------------------------------------------------------*/

        /**
         * Start otp request
         * (set in login otp request request form)
         *
         * @returns {Promise}
         */
        async otpRequest() {
            if (!this.get(this.model, 'g-recaptcha-response')) {
                await this.verifyForgetRecaptcha();
            }
            await this.loginRequestOtp();
        },

        /**
         * Start login otp request
         *
         * @returns {Promise}
         */
        loginRequestOtp() {
            this.$wait.start('submitting-otp-request');

            return this.$services.Person.loginRequestOtp({
                username: this.username,
            }).then((response) => {
                this.afterSuccessLoginRequestOtp(response);
            }).catch((error) => {
                this.handleError(error);
                this.afterErrorLoginRequestOtp(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-otp-request');
            });
        },

        /**
         * Custom Login Request Otp action
         * Should be overwritten in otp page
         *
         * @param {object} response - Login Request Otp response
         */
        afterSuccessLoginRequestOtp(response) { // eslint-disable-line no-unused-vars

        },

        /**
         * Custom Login Request Otp action
         * Should be overwritten in otp page
         *
         * @param {object} error - Login Request Otp error
         */
        afterErrorLoginRequestOtp(error) { // eslint-disable-line no-unused-vars

        },

        /**
         * Start Login Second Factor
         *
         * @param {string} code - receive code
         * @returns {Promise}
         */
        loginSecondFactor(code) {
            this.$wait.start('submitting-login-second-factor');

            return this.$services.Person.loginSecondFactor({
                'g-recaptcha-response': this.model['g-recaptcha-response'],
                code,
            }).then((response) => {
                this.handleLoginResponse(response);
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-login-second-factor');
            });
        },

        /**
         * Start Login Send Otp
         *
         * @param {string} channel - channel of receive token
         * @returns {Promise}
         */
        loginSendOtp(channel) {
            this.$wait.start('submitting-login-send-otp');

            return this.$services.Person.loginSendOtp({
                'g-recaptcha-response': this.model['g-recaptcha-response'],
                username              : this.username,
                channel,
            }).then((response) => {
                this.afterSuccessLoginSendOtp(response);
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-login-send-otp');
            });
        },

        /**
         * Custom Login Send Otp action
         * Should be overwritten in otp page
         *
         * @param {object} response - Login Send Otp response
         */
        afterSuccessLoginSendOtp(response) { // eslint-disable-line no-unused-vars

        },

        /**
         * Start Login With Otp
         *
         * @param {string} channel - channel of receive token
         * @param {string} code - receive code
         * @returns {Promise}
         */
        loginWithOtp(channel, code) {
            this.$wait.start('submitting-login-with-otp');

            return this.$services.Person.loginWithOtp({
                'g-recaptcha-response': this.model['g-recaptcha-response'],
                username              : this.username,
                token                 : code,
                channel,
            }).then((response) => {
                this.handleLoginResponse(response);
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.resetRecaptcha();
                this.verifyForgetRecaptcha();
                this.$wait.end('submitting-login-with-otp');
            });
        },
    },
};
