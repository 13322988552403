<template>
    <y-panel class="ov-h">
        <div
            v-if="settings.usernameFields"
            class="auth-form"
        >
            <transition
                name="fadeDown"
                mode="out-in"
            >
                <y-check-username
                    v-if="step === 1"
                    key="check-username"
                    v-model="model"
                    :settings="settings"
                    @done="next"
                />

                <y-select-channel
                    v-if="step === 2"
                    key="select-channel"
                    v-model="model"
                    :settings="settings"
                    @done="next"
                />

                <y-two-f-a-verify-code
                    v-if="step === 3 && twoFA"
                    key="two-fa-verify-code"
                    @tokenVerified="handleLoginResponse"
                />

                <y-verify-code
                    v-if="step === 3 && !twoFA"
                    key="verify-code"
                    v-model="model"
                    :settings="settings"
                />
            </transition>
        </div>
    </y-panel>
</template>

<script>
    import AuthMixin from '@/modules/auth/mixins/Auth';

    import YCheckUsername from '@/modules/auth/components/auth/CheckUsername';
    import YSelectChannel from '@/modules/auth/components/auth/SelectChannel';
    import YTwoFAVerifyCode from '@/modules/auth/components/auth/TwoFAVerifyCode';
    import YVerifyCode from '@/modules/auth/components/auth/VerifyCode';

    export default {

        name: 'OTPLogin',

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('auth.page_title.otp'),
            };
        },

        components: {
            YCheckUsername,
            YSelectChannel,
            YTwoFAVerifyCode,
            YVerifyCode,
        },

        mixins: [AuthMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                step : 1,
                model: {},
                
                settings: {
                    usernameFields: this.setting('login_form.otp_fields'),

                    recaptcha: this.setting('recaptcha'),
                },
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.logout();
        },

        methods: {
            /**
             * Change steps to next
             *
             * @param settings
             */
            next(settings) {
                this.settings = { ...this.settings, ...settings };
                if (this.settings.selectedChannel === 'two_factor') {
                    this.twoFA = true;
                }
                this.step += 1;
            },
            
            /**
             * Logout user
             */
            logout() {
                this.$store.commit('auth/logout');
            },

            /**
             * Tasks to be done after login
             */
            afterSuccessLogin() {
                const redirect = this.cloneDeep(this.$store.getters['auth/redirect']);
                if (redirect) {
                    this.$store.dispatch('auth/resetRedirect').then(() => {
                        this.$router.push(redirect);
                    });
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            },
        },
    };
</script>
