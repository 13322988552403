<template>
    <div>
        <form
            id="request_form"
            @submit.prevent="nextStep"
        >
            <y-form-field
                v-model="model.username"
                v-validate="'required'"
                name="request_password_recovery"
                :placeholder="usernameLabel"
            />

            <y-button
                block
                color="blue"
                button-type="submit"
                form="request_form"
                :disabled="!model.username"
                @click="$emit('apply')"
            >
                {{ $t('auth.send') }}
            </y-button>
        </form>
    </div>
</template>

<script>

    // Mixins
    import FormMixin from '@/mixins/Form';

    // Components
    import { YFormField } from '@deps';

    export default {
        name: 'ForgetGetUsername',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Model value
             */
            value: {
                type: Object,
            },
            settings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : this.value,
                separator: this.$i18n.locale === 'fa' ? '، ' : ', ',
            };
        },

        computed: {
            /**
             * Return username label
             */
            usernameLabel() {
                const { usernameFields } = this.settings;
                if (!usernameFields) {
                    return this.$t('fields.username');
                }

                const labelArray = usernameFields.map((field) => this.$t(`fields.${field}`));
                return labelArray.join(this.separator);
            },

        },

        methods: {
            /**
             * save reset password credentials and move up to the next step
             */
            nextStep() {
                this.$emit('done', this.model);
            },

        },
    };
</script>
